import React from "react";
import Block from "gatsby-theme-hypersite/src/main/block";
import { Typography, withStyles, Button } from "@material-ui/core";
import { compactTitleTypography, serifTypography } from "../../gatsby-theme-hypercore/theme";
import moment from "moment";

const styles = (theme) => ({
  hero: {
    paddingTop: theme.spacing(20),
    [theme.breakpoints.up("md")]: {
      //paddingRight: theme.columnSpacing(2),
    },
  },
  date: {
    fontSize: theme.typography.pxToRem(14),
  },
  itemDetails: {
    fontFamily: "neue-haas-grotesk-display, sans-serif",
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  postTitle: {
    ...serifTypography,
    color: theme.palette.text.secondary,
    lineHeight: 1.05,
    margin: 0,
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(44),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(32),
    },
},
  pdfUrl: {
    textDecoration: 'none',
  },
  divider: {
    margin: '0 8px',
  },
  button: {
    fontSize: 14,
    padding: '.4rem 3rem',
  }
});

const StoryHero = ({ classes, author, date, title, post, ...props }) => {
  const formattedDate = moment(date).format("MMMM Do, YYYY");
  const divider = <span className={classes.divider}>{`\u2022`}</span>
  const description = post.participantDescription ? <span>{divider}{post.participantDescription}</span> : ''
  const state = post.state ? <span>{divider}{post.state.split(", ").sort().join(", ")}</span> : ''

  return (
    <Block>
      <div className={classes.hero} {...props}>
        <span className={classes.itemDetails}>{formattedDate}{description}{state}</span>
        <Typography variant="h2" component="h2" className={classes.postTitle}>
          {post.participantName}
        </Typography>
        <a className={classes.pdfUrl} href={post.pdfUrl}><Button className={classes.button}>Open PDF</Button></a>
      </div>
    </Block>
  );
};

export default withStyles(styles)(StoryHero);

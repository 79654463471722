import React from "react";
import { withStyles } from "@material-ui/core";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { sansSerifyTypography } from "../../gatsby-theme-hypercore/theme";

const styles = (theme) => ({
  table: {
    marginBottom: theme.spacing(4),
    '& th, & td': {
      ...sansSerifyTypography,
      border: '1px solid #283224',
      color: '#283224',
      fontSize: 14,
      padding: '15px 12px',
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        lineHeight: 1.4,
        padding: '12px 10px',
      },
    },
    '& th': {
      fontWeight: 'bold',
    },
  }
});

const StoryTable = ({ post, classes, ...props }) => {
  return (
    <TableContainer className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Participant Name</TableCell>
            <TableCell>Participant Initials</TableCell>
            <TableCell>Description (Role/Job)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{post.participantName}</TableCell>
            <TableCell>{post.participantInitials}</TableCell>
            <TableCell>{post.participantDescription}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{post.interviewerName}</TableCell>
            <TableCell>{post.interviewerInitials}</TableCell>
            <TableCell>{post.interviewerDescription}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withStyles(styles)(StoryTable);

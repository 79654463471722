import React from "react"
import { graphql } from "gatsby"
import Layout from "gatsby-theme-hypersite/src/layout";
import Content from "../components/Content";
import StoryHero from "../components/stories/StoryHero";
import { withStyles } from "@material-ui/core";
import { serifTypography } from "../gatsby-theme-hypercore/theme";
import StoryTable from "../components/stories/StoryTable";

const styles = (theme) => ({
  content: {
    ...serifTypography,
    backgroundColor: '#FFFFFF',
    paddingBottom: theme.spacing(8),
    fontSize: 19,
    paddingTop: theme.spacing(8),
    '& p': {
      color: '#283224',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    }
  },
});

const Story = ({ data, classes }) => {
  const post = data.allStories.nodes[0]

  function formattedText() {
    var text = ''
    const inteviewText = data.allStories.nodes[0].interviewText
    const contText = data.allStories.nodes[0].interviewTextCont ? data.allStories.nodes[0].interviewTextCont : ''

    inteviewText.split('\n').forEach(graph => {
      text += `<p>${graph}</p>`
    });
    if (contText) {
      contText.split('\n').forEach(graph => {
        text += `<p>${graph}</p>`
      });        
    }
    text = text.replace(/<p>\s<\/p>/g, "");
    return text
  }

  return (
    <Layout>
      <StoryHero post={post} />
      <Content className={classes.content}>
        <StoryTable post={post} />
        <div dangerouslySetInnerHTML={{__html: formattedText()}} />
      </Content>
    </Layout>
  );
};
export default withStyles(styles)(Story);

export const query = graphql`
  query($name: String!) {
    allStories(filter: {participantName: { eq: $name } }) {
      nodes {
        id
        date
        interviewText
        interviewTextCont
        participantName
        participantInitials
        participantDescription
        interviewerName
        interviewerInitials
        interviewerDescription
        pdfUrl
        state
      }
    }
  }
`